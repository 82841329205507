<template>
	<el-container class="home-container">
		<el-header>
			<div>
				<!-- <img src="../assets/images/welcome.png" alt="" /> -->
				<span>管理后台</span>
			</div>
			<div>
				<el-button v-show="showbigdata" @click="toBidata" type="primary">可视化大数据<i class="el-icon-upload el-icon--right"></i>
				</el-button>
				<el-button type="info" @click="logout">退出</el-button>
			</div>
		</el-header>
		<el-container>
			<el-aside :width="isCollapse ? '64px' : '200px'">
				<div class="toggle-btn" @click="toggleCollapse">|||</div>
				<el-menu mode="vertical" background-color="#333744" text-color="#fff" active-text-color="#409bff"
					unique-opened :collapse="isCollapse" :collapse-transition="false" :router="true"
					:default-active="activePath">
					<template v-for="item in menuList">
						<el-submenu :key="item.id" :index="item.id + ''" v-if="item.children">
							<template slot="title">
								<i :class="iconsObj[item.id]"></i>
								<span>{{ item.authName }}</span>
							</template>
							<el-menu-item :index="'/' + subMenu.path" v-for="subMenu in item.children" :key="subMenu.id"
								@click="saveNavState('/' + subMenu.path)">
								<template slot="title">
									<i class="el-icon-menu"></i>
									<span>{{ subMenu.authName }}</span>
								</template>
							</el-menu-item>
						</el-submenu>
						<el-menu-item :key="item.id" :index="'/' + item.path" @click="saveNavState('/' + item.path)"
							v-else>
							<i :class="iconsObj[item.id]"></i>
							<template slot="title">
								<span>{{ item.authName }}</span>
							</template>
						</el-menu-item>
					</template>
				</el-menu>
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>
<script>
	export default {
		components: {},
		data() {
			return {
				showbigdata:false,
				menuList: [{
						id: 1,
						authName: "数据概览",
						path: "index",
						order: 1,
					},
					{
						id: 2,
						authName: "情绪识别2",
						// path: "emotion",
						children: [{
								id: 201,
								authName: "监测记录",
								path: "warning",
								children: [],
								order: null,
							},
							{
								id: 202,
								authName: "预警记录",
								path: "warning",
								children: [],
								order: null,
							},
						],
						order: 2,
					},
					{
						id: 3,
						authName: "权限管理",
						path: "permissions",
						order: 3,
					},
					{
						id: 4,
						authName: "组织架构",
						children: [{
								id: 401,
								authName: "部门管理",
								path: "department",
								children: [],
								order: null,
							},
							{
								id: 402,
								authName: "人员管理",
								path: "personnel",
								children: [],
								order: null,
							},
						],
						order: 4,
					},
					{
						id: 5,
						authName: "账号设置",
						path: "account",
						order: 5,
					},
				],
				iconsObj: {
					1: "el-icon-s-home",
					2: "el-icon-view",
					3: "el-icon-unlock",
					4: "el-icon-user",
					5: "el-icon-setting",
					6: "el-icon-pie-chart",
					7: "el-icon-monitor",
					8: "el-icon-folder",
				},
				isCollapse: false,
				activePath: "/index",
			};
		},
		computed: {},
		watch: {},
		methods: {
			logout() {
				this.$router.push("/login");
			},
			toBidata() {
				this.$router.push("/visualizations");
			},
			toggleCollapse() {
				this.isCollapse = !this.isCollapse;
			},
			saveNavState(avtivePath) {
				sessionStorage.setItem("activePath", avtivePath);
				this.activePath = avtivePath;
			},
		},
		created() {
			this.activePath = sessionStorage.getItem('activePath')
			let level = sessionStorage.getItem("level");
			let power = sessionStorage.getItem("power");
			let powerlist = [];
			let plist = JSON.parse(power);
			for(let i in plist){
				powerlist.push(plist[i].label)
			}
			if (level == 0) {
				this.menuList = [{
					id: 6,
					authName: "团队管理",
					path: "team",
					order: 6,
				},{
					id: 7,
					authName: "权限设置",
					path: "power",
					order: 7,
				}]
			}else{
			// if (level == 1 ) {
				this.menuList = [];
				if(powerlist.includes('可视大屏') && level == 1){
					this.showbigdata = true;
				}
				if(powerlist.includes('数据概览')){
					this.menuList.push({
							id: 1,
							authName: "数据概览",
							path: "index",
							order: 1,})
						
				}
				if(powerlist.includes('监测记录')||powerlist.includes('预警记录')){
					let children = [];
					if(powerlist.includes('监测记录')){
						children.push({
									id: 201,
									authName: "监测记录",
									path: "monttoring",
									children: [],
									order: null,
								})
					}
					if(powerlist.includes('预警记录')){
						children.push({
									id: 202,
									authName: "预警记录",
									path: "warning",
									children: [],
									order: null,
								})
					}
					this.menuList.push({
							id: 2,
							authName: "情绪识别",
							children,
							order: 2,
						})
				}
				if(powerlist.includes('权限管理') && level == 1){
					this.menuList.push({
						id: 3,
						authName: "权限管理",
						path: "permissions",
						order: 3,
					})
				}
				if((powerlist.includes('部门管理') && level == 1)||powerlist.includes('人员管理')){
					let children = [];
					if(powerlist.includes('部门管理') && level == 1){
						children.push({
									id: 401,
									authName: "部门管理",
									path: "department",
									children: [],
									order: null,
								})
					}
					if(powerlist.includes('人员管理')){
						children.push({
									id: 402,
									authName: "人员管理",
									path: "personnel",
									children: [],
									order: null,
								})
					}
					if(children.length){
						this.menuList.push({
								id: 4,
								authName: "组织架构",
								children,
								order: 4,
							})
					}
				}
				if(powerlist.includes('账号设置')){
					this.menuList.push({
						id: 5,
						authName: "账号设置",
						path: "account",
						order: 5,
					})
				}
				if(powerlist.includes('定位地图')){
					this.menuList.push({
						id: 6,
						authName: "定位地图",
						path: "location",
						order: 6,
					})
				}
				if(powerlist.includes('设备管理')){
					this.menuList.push({
                    id: 7,
                    authName: "设备管理",
                    path: "equipment",
                    order: 7,
                })
				}
				if(powerlist.includes('操作日志')){
					this.menuList.push({
                    id: 8,
                    authName: "操作日志",
                    path: "log",
                  })
				}
				
				// this.menuList = [
				// 	{
				// 		id: 1,
				// 		authName: "数据概览",
				// 		path: "index",
				// 		order: 1,
				// 	},
				// 	{
				// 		id: 2,
				// 		authName: "情绪识别",
				// 		// path: "emotion",
				// 		children: [{
				// 				id: 201,
				// 				authName: "监测记录",
				// 				path: "monttoring",
				// 				children: [],
				// 				order: null,
				// 			},
				// 			{
				// 				id: 202,
				// 				authName: "预警记录",
				// 				path: "warning",
				// 				children: [],
				// 				order: null,
				// 			},
				// 		],
				// 		order: 2,
				// 	},
				// 	{
				// 		id: 3,
				// 		authName: "权限管理",
				// 		path: "permissions",
				// 		order: 3,
				// 	},
				// 	{
				// 		id: 4,
				// 		authName: "组织架构",
				// 		children: [{
				// 				id: 401,
				// 				authName: "部门管理",
				// 				path: "department",
				// 				children: [],
				// 				order: null,
				// 			},
				// 			{
				// 				id: 402,
				// 				authName: "人员管理",
				// 				path: "personnel",
				// 				children: [],
				// 				order: null,
				// 			},
				// 		],
				// 		order: 4,
				// 	},
				// 	{
				// 		id: 5,
				// 		authName: "账号设置",
				// 		path: "account",
				// 		order: 5,
				// 	},
				// 	{
				// 		id: 6,
				// 		authName: "定位地图",
				// 		path: "location",
				// 		order: 6,
				// 	},
				// 	{
    //                 id: 7,
    //                 authName: "设备管理",
    //                 path: "equipment",
    //                 order: 7,
    //             },
				// 	{
    //                 id: 8,
    //                 authName: "操作日志",
    //                 path: "log",
    //               }
				// ]
			}
			// if (level == 2 || level == 3 || level == 4 || level == 5) {
			// 	this.menuList = [{
			// 			id: 1,
			// 			authName: "数据概览",
			// 			path: "index",
			// 			order: 1,
			// 		},
			// 		{
			// 			id: 2,
			// 			authName: "情绪识别",
			// 			// path: "emotion",
			// 			children: [{
			// 					id: 201,
			// 					authName: "监测记录",
			// 					path: "monttoring",
			// 					children: [],
			// 					order: null,
			// 				},
			// 				{
			// 					id: 202,
			// 					authName: "预警记录",
			// 					path: "warning",
			// 					children: [],
			// 					order: null,
			// 				},
			// 			],
			// 			order: 2,
			// 		},
			// 		{
			// 			id: 4,
			// 			authName: "组织架构",
			// 			children: [{
			// 				id: 402,
			// 				authName: "人员管理",
			// 				path: "personnel",
			// 				children: [],
			// 				order: null,
			// 			}, ],
			// 			order: 4,
			// 		},
			// 		{
			// 			id: 5,
			// 			authName: "账号设置",
			// 			path: "account",
			// 			order: 5,
			// 		},
			// 		{
			// 			id: 6,
			// 			authName: "定位地图",
			// 			path: "location",
			// 			order: 6,
			// 		},
			// 	]
			// }
			// if(!powerlist.includes("数据概览")){
			// 	if(this.menuList[0].path){
			// 		this.activePath = this.menuList[0].path;
			// 	}else{
			// 		this.activePath = "";
			// 	}
			// }
		},
		mounted() {},
		beforeCreate() {},
		beforeMount() {},
		beforeUpdate() {},
		updated() {},
		beforeDestroy() {},
		destroyed() {},
		activated() {},
	};
</script>
<style lang="less" scoped>
	.home-container {
		height: 100%;
	}

	.el-header {
		background-color: #373d41;
		display: flex;
		justify-content: space-between;
		padding-left: 0px;
		align-items: center;
		color: #fff;
		font-size: 20px;

		div {
			display: flex;
			align-items: center;

			img {
				width: 50px;
				margin-left: 10px;
			}

			span {
				margin-left: 15px;
			}
		}
	}

	.el-aside {
		background-color: #333744;

		.el-menu {
			border: none;
		}
	}

	.el-main {
		background-color: #eaedf1;
	}

	.iconfont {
		margin-right: 10px;
	}

	.toggle-btn {
		background-color: #4a5064;
		color: #fff;
		font-size: 10px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.2em;
		cursor: pointer;
	}
</style>
